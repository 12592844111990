import React from "react";
import PromotionBanner from "./PromotionBanner";
import Promotion from "./Promotion";
import Disclaimer from "./Disclaimer";
import BrokerPromotionHelmet from "../Helmets/BrokerPromotionHelmet"


export default function Index() {
  return (
    <div className="container">
      <BrokerPromotionHelmet/>
      <PromotionBanner />
      <Promotion />
      <Disclaimer/>
      
    </div>
  );
}
